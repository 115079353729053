import * as React from "react";
import { Single, ContactForm } from "gatsby-frontend/plugins/gatsby-frontend-material";
import { Grid, useTheme, Container } from "@mui/material";
import { GoogleReCaptchaProvider } from "gatsby-frontend";

const GATSBY_RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;

const QuestionsPage = () => {
	const formOptions = {
		title: "Do you have any question?",
		messageLabel: "Question",
		hiddenInputs: ["last_name"],
	};

	const theme = useTheme();

	const onSubmitForm = async (questionFormValues, baseUrl) => {
		try {
			let response = await fetch(`${baseUrl}/question_form_email`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(questionFormValues),
			});

			if (response.ok) {
				let data = await response.json();
				return data.data.success;
			}

			return false;
		} catch (e) {
			console.console.log("Error sendig the question form submission");
			console.error(e);
			return false;
		}
	};

	if (!GATSBY_RECAPTCHA_SITE_KEY) {
		return (
			<Single>
				<Container maxWidth="xl" sx={{ padding: theme.spacing(3, 0) }}>
					<Grid container spacing={5} sx={{ justifyContent: "center" }}>
						<Grid item xs={6}>
							<ContactForm options={formOptions} onSubmitForm={onSubmitForm} />
						</Grid>
					</Grid>
				</Container>
			</Single>
		);
	}

	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
			<Single>
				<Container maxWidth="xl" sx={{ padding: theme.spacing(3, 0) }}>
					<Grid container spacing={5} sx={{ justifyContent: "center" }}>
						<Grid item xs={6}>
							<ContactForm options={formOptions} onSubmitForm={onSubmitForm} />
						</Grid>
					</Grid>
				</Container>
			</Single>
		</GoogleReCaptchaProvider>
	);
};

export default QuestionsPage;
